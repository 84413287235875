import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/(app)": [~5,[2]],
		"/(app)/analytics": [6,[2]],
		"/(app)/buy": [~7,[2,3]],
		"/(app)/buy/broadcast": [~8,[2,3]],
		"/(public)/forgot-password": [~33,[4]],
		"/(public)/invite": [~34,[4]],
		"/(public)/invite/invalid": [35,[4]],
		"/(public)/invite/success": [36,[4]],
		"/(public)/join": [~37,[4]],
		"/(public)/login": [~38,[4]],
		"/(public)/logout": [~39,[4]],
		"/(app)/negotiation/bid": [~17,[2]],
		"/(app)/negotiation/offer": [~18,[2]],
		"/(app)/negotiation/[negotiationId]": [~9,[2]],
		"/(app)/negotiation/[negotiationId]/accept": [~10,[2]],
		"/(app)/negotiation/[negotiationId]/cancel": [~11,[2]],
		"/(app)/negotiation/[negotiationId]/contract/[contractId]": [~12,[2]],
		"/(app)/negotiation/[negotiationId]/contract/[contractId]/addProducts": [~13,[2]],
		"/(app)/negotiation/[negotiationId]/contract/[contractId]/finalize": [~14,[2]],
		"/(app)/negotiation/[negotiationId]/counter": [~15,[2]],
		"/(app)/negotiation/[negotiationId]/reject": [~16,[2]],
		"/(public)/new-password": [~40,[4]],
		"/(app)/positions": [~19,[2]],
		"/(app)/positions/data": [~20,[2]],
		"/(app)/positions/data/create": [~22,[2]],
		"/(app)/positions/data/import": [~23,[2]],
		"/(app)/positions/data/[type]/[positionId]/update": [~21,[2]],
		"/(app)/positions/deliveries": [~24,[2]],
		"/(app)/positions/examples": [25,[2]],
		"/(app)/prices": [~26,[2]],
		"/(app)/products": [~27,[2]],
		"/(app)/products/create": [~29,[2]],
		"/(app)/products/[productId]": [~28,[2]],
		"/(public)/reset-password": [~41,[4]],
		"/(app)/sell": [~30,[2]],
		"/(app)/sell/broadcast": [~31,[2]],
		"/(app)/settings": [~32,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';