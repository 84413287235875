import '$lib/client/init.js'
import 'intl-tel-input/build/css/intlTelInput.css'
import telInput from 'intl-tel-input/intlTelInputWithUtils'
import {
	PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
	PUBLIC_SENTRY_DSN_WEB,
	PUBLIC_SST_STAGE
} from '$env/static/public'
import { handleErrorWithSentry } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'
import { createLogger } from '@puebla/logger'
import * as FeatureFlags from '$lib/client/featureFlags.js'
import { baseConfig, feedback, replay, replayConfig } from '$lib/sentry.js'
import { globals } from '$lib/globals.js'

const logger = createLogger('client-hooks')
logger.info('stage', PUBLIC_SST_STAGE)

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN_WEB,
	environment: PUBLIC_SST_STAGE,
	...baseConfig(PUBLIC_SST_STAGE),
	...replayConfig(PUBLIC_SST_STAGE),
	integrations: [replay!, feedback!]
})

FeatureFlags.init(PUBLIC_LAUNCH_DARKLY_CLIENT_ID)

//setup phone input support as it can only be imported from a file
//only used on the client
globals.phoneInput = telInput as any

try {
	const countryCode = localStorage.getItem('countryCode')

	if (!countryCode) {
		fetch('https://ipapi.co/json', {
			signal: AbortSignal.timeout(2000)
		})
			.then(resp => resp.json())
			.then(data => {
				localStorage.setItem(
					'countryCode',
					data.country_code?.toLowerCase() || 'co'
				)
			})
			.catch(err => {
				logger.error('geo lookup error', err)
			})
	}
} catch (err: any) {
	logger.error('error initializing country code', err)
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
